import React from 'react';
import Layout from '../../components/layoutj';
import '../../pages/empresa.css';
import styled from "styled-components"

const Content = styled.article`
  text-align:left
`

const sevebuenazo = () => {
  return (
    <Layout >
      <main className="contenedor">
        <Content>


          <h4>
          コンクールについて』<br/>
          このコンクールの目的は、パテヤ（料理）の調理を奨励することです。</h4>

          <h5>・ルール説明・条件</h5>
          <br/>
          <p>
          <br/>1.	Ｇ&Ｃコーポレーションの商品である、ペルーチェフ・カプリチョーザ・フラミディア
          ・ドニャメチェのブランドを一つか複数を使用して料理をする
          ※ペルー料理に限らず、いろんなお国の調理でも可能です。

          <br/>2.	送って頂いた画像のオリジナリティ、見た目、デコレーションが評価されます
          ※調理方法や写真の撮り方は自由です。

          <br/> 3.	抽選で3名様にG&Cコーポレーションの商品が入ったバスケットをプレゼントします。

          <br/>4.	未成年者もコンペティションに参加できます。
          ※ただし、保護者の責任の下で

          <br/>5.	7月25日（日）までに参加者1名につき1枚の画像(デジタルフォト)を電子メールでご
          送付ください。

          <br/>6.	結果は、今年の7月30日に発表され、企業の様々なメディアに掲載されるほか、G&C
          コーポレーションのWebページや弊社のFacebookにも掲載されます。

          <br/>7.	コンクール用の画像の送り先は、sevenuenazo@gyc-corp.com
          になります。</p>
          <br/>
          <h5>・参加者へのお願い</h5>
          <br/>
          <p>
          <br/>1.	画像またはデジタル写真（携帯電話やデジタルカメラで撮影したもの）を添付した
          メールを送信すること。

          <br/>2.	料理に使用したG&Cコーポレーションの商品（複数可）が写真に写っていること。
          <br/>3.メールを送信する際には以下の情報を必ず記載してください。
          <br/>a) 料理のレビュー
          <br/>b) フルネームおよび/またはペンネーム
          <br/>c) 住所（郵便番号を含む）
          <br/>d) 連絡先電話番号
          <br/>e) 国籍
          <br/>f)生年月日
          <br/>4.同一のメールアドレスで複数の参加者の画像を送信する場合は、個別にメールを送
          信する必要があります（1つのメールで1人の参加者）。
          個別に（1つのメール/1人の参加者）、以下のすべてのデータを正式に添付してください。
          （要求されたデータ）。
          <br/>5.日本国内に居住していること。
          <br/>6.データや画像は、当社が独占的かつ内部的に使用するものです。
          ・優勝者について
          G&Cコーポレーションが本コンクールの結果を公表するために、自身のデータを公開す
          <br/>

          </p><br/>
          <h5>ることを許諾します。</h5>
          <p>
          <br/>1.	コンクールの結果。

          <br/>2.	受賞者3名の写真。
          <br/>※日本の企業の年鑑に掲載されます。
          <br/>2022年の企業年鑑は、その年の12月に配布される予定で、その際にはコンクールのレ
          <br/>ビューが掲載されます。

          <br/>３.3つの賞品は、事前にご連絡の上、当選者のご住所に直接お送りいたします。
          ※ご連絡方法：電話や電子メールでご連絡させていただきます。

          <br/>4.賞品は、電子メールに記載されている住所に送られます。

          <br/>5.前述の点を変更した場合、その費用は勝者が負担するものとします。

          <br/>6.賞品の放棄は、いかなる補償、賠償の権利も与えません。

          <br/>7.最長1週間以内に当選者との連絡が取れない場合は、賞品は参加者の中から抽選を
          行います。
          </p>
        </Content>
      </main>
    </Layout>
  );
}

export default sevebuenazo;